$(`.link-ancor`).on(`click`, function(evt) {
  evt.preventDefault();

  const id = $(this).attr(`href`);
  const top = $(id).offset().top;

  $(`body, html`).animate({
    scrollTop: top - 64
  }, 500);
});
