if (window.matchMedia(`(max-width: 767px)`).matches) {
  $(`.page-main__top-adv`).insertAfter(`.heading--h1-index`);
}

$(`.slider-index`).slick({
  lazyLoad: `ondemand`,
  dots: true,
  rows: false,
  autoplay: true,
  responsive: [{
    breakpoint: 767,
    settings: {
      arrows: false
    }
  }]
});

$(`.slider-week`).slick({
  lazyLoad: `ondemand`,
  dots: true,
  rows: false,
  responsive: [{
    breakpoint: 767,
    settings: {
      arrows: false
    }
  }]
});

$(`.featured__slider--type`).slick({
  lazyLoad: `ondemand`,
  dots: false,
  rows: false,
  responsive: [{
    breakpoint: 767,
    settings: {
      arrows: false,
      dots: true
    }
  }]
});

$(`.featured__slider--class`).slick({
  lazyLoad: `ondemand`,
  dots: false,
  rows: false,
  responsive: [{
    breakpoint: 767,
    settings: {
      arrows: false
    }
  }]
});

$(`.slider-banks`).slick({
  lazyLoad: `ondemand`,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  infinite: true,
  rows: false,
  responsive: [{
    breakpoint: 1900,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 3
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 1
    }
  }]
});

$(`.slider-car--card`).slick({
  lazyLoad: `ondemand`,
  arrows: true,
  dots: false,
  rows: false,
  slidesToShow: 1,
  asNavFor: `.slider-nav-car`,
  responsive: [{
    breakpoint: 1900,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 1
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 1,
      arrows: false
    }
  }]
});

$(`.slider-car--dealer`).slick({
  lazyLoad: `ondemand`,
  arrows: true,
  dots: true,
  slidesToShow: 1,
  responsive: [{
    breakpoint: 1900,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 1
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 1,
      arrows: false
    }
  }]
});

$(`.slider-nav-car`).slick({
  arrows: false,
  dots: false,
  slidesToShow: 9,
  rows: false,
  asNavFor: `.slider-car--card`,
  centerMode: false,
  focusOnSelect: true,
  slidesToScroll: 1,
  adaptiveHeight: true,
  vertical: true,
  verticalSwiping: true,
  responsive: [{
    breakpoint: 1199,
    settings: {
      vertical: false,
      verticalSwiping: false
    }
  }],
});

if (window.matchMedia(`(min-width: 1199px)`).matches) {
  $(`.selection__catalog`).slick({
    lazyLoad: `ondemand`,
    arrows: true,
    dots: false,
    autoplay: false,
    slidesToShow: 4,
    rows: false
  });
}

$(`.slider-dealer`).slick({
  lazyLoad: `ondemand`,
  arrows: true,
  dots: false,
  slidesToShow: 2,
  rows: false,
  responsive: [{
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

// $(`.slider-dealer`).slick({
//   lazyLoad: `ondemand`,
//   arrows: true,
//   dots: false,
//   autoplay: false,
//   slidesToShow: 1,
//   rows: false
// });

$('.popular__list').slick({
  arrows: true,
  dots: false,
  slidesToShow: 3,
  rows: false,
  responsive: [{
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

if (window.matchMedia(`(max-width: 767px)`).matches) {
  $(`.page-banner__wrap`).slick({
    arrows: true,
    rows: false
  });
}
