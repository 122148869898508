const readUrl = (input) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = function(e) {
        $(`#image-preview`).css(`background-image`, `url(`+e.target.result +`)`);
        $(`#image-preview`).hide();
        $(`#image-preview`).removeClass(`review__preview--empty`);
        $(`.review__preview-remove`).removeClass(`visually-hidden`);
        $(`#image-preview`).fadeIn(650);

    }
    reader.readAsDataURL(input.files[0]);
  }
};

$(`.review__preview-remove`).click(function(evt) {
  evt.preventDefault();
  $(this).addClass(`visually-hidden`);
  $(`#image-preview`).css(`background-image`, ``);
  $(`#image-preview`).addClass(`review__preview--empty`);
});


$(`#image-upload`).change(function() {
  readUrl(this);
});
