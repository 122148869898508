var i, c, y, v, s, n;
v = document.getElementsByClassName("youtube");
if (v.length > 0) {
  s = document.createElement("style");
  s.type = "text/css";
  s.innerHTML = '';
  document.body.appendChild(s);
}
for (n = 0; n < v.length; n++) {
  y = v[n];
  i = document.createElement("img");
  i.setAttribute(
    "data-src",
    "../img/youtube-cover.jpg"
  );
  i.setAttribute("class", "thumb lazyload");
  c = document.createElement("div");
  c.setAttribute("class", "play");
  y.appendChild(i);
  y.appendChild(c);
  y.onclick = function () {
    var a = document.createElement("iframe");
    a.setAttribute(
      "src",
      "https://www.youtube.com/embed/" +
        this.id +
        "?autoplay=1&autohide=1&border=0&wmode=opaque&enablejsapi=1"
    );
    a.style.width = this.style.width;
    a.style.height = this.style.height;
    this.parentNode.replaceChild(a, this);
  };
}
